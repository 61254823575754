<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#">Website</b-breadcrumb-item>
                <b-breadcrumb-item href="#" active>Menu</b-breadcrumb-item>
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-row>
                <b-col md="12">
                    <b-card type="default" header-classes="bg-transparent">
                    <b-row>
                        <b-col md="3">
                            <b-input v-model="categoryMenu" type="text" placeholder="Menu Title"></b-input>
                        </b-col>
                        <b-col md="2">
                            <base-button @click="addMenuCategory()" block type="primary">Add Menu Title</base-button>
                        </b-col>
                    </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-row style="margin-top: 20px;">
                <b-col md="12">
                    <b-card type="default" header-classes="bg-transparent">
                        <b-row>
                            <b-col md="12">
                                <label>Select Menu Type</label>
                                <select class="custom-select" v-model="menuCategory" @change="selectMenuData($event)">
                                  <option value="0"></option>
                                  <option 
                                  :value="menuCategoryData.menu_id" 
                                  v-for="(menuCategoryData, menuCategoryKey) in menuCategoryList " 
                                  :key="menuCategoryKey">
                                    {{menuCategoryData.menu_category}}
                                  </option>
                                </select>
                            </b-col>
                            <b-col md="6" class="mt-4" v-if="menuData">
                              <JsonEditor
                                :options="{
                                confirmText: 'confirm',
                                cancelText: 'cancel',
                                }"
                                :objData="menuData" 
                                v-model="menuData" >
                              </JsonEditor>
                            </b-col>
                            <b-col md="6" class="mt-4" style="text-align: left;" v-if="menuData">
                                <pre><code>{{JSON.stringify(menuData, null, 4)}}</code></pre>
                            </b-col>
                        </b-row>
                        <b-row v-if="menuData">
                            <b-col md="9"></b-col>
                            <b-col md="3" class="mt-4"><base-button @click="updateMenu(menuId)" block type="primary">Update Menu</base-button></b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import { API_URL } from '../../../router/api'
export default {
  name: 'webiste-image-upload',
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data () {
    return {
      isActive: false,
      checked: true,
      categoryMenu: null,
      menuCategory: null,
      menuData: null,
      menuCategoryList: null,
      menuId: 0
    }
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    addMenuCategory() {
      const self = this;
      if(this.categoryMenu) {
        this.axios.post(API_URL+'admin_menu_category',{category: this.categoryMenu})
        .then((response) => {
          self.viewMenuCategory()
          let obj = response.data
          obj.status = true ? this.$store.dispatch('alertSuccess', obj.message) : this.$store.dispatch('alertError', obj.message);
          self.categoryMenu = null;
        })
      }else {
        this.$store.dispatch('alertError', " Menu Category Empty")
      }
      
    },
    viewMenuCategory() {
      const self = this;
      this.axios.post(API_URL+'admin_menu_category_view')
      .then((response) => {
        console.log(response.data);
        self.menuCategoryList = response.data
      })
    },
    selectMenuData(event) {
      const self = this;
      // console.log(JSON.parse(event.target.value));
      this.menuId = event.target.value;
      console.log(this.menuId);
      if(event.target.value != 0) {
        this.axios.post(API_URL+'admin_menu_json',{id: event.target.value})
        .then((response) => {
          console.log(response.data.menu_json);
          self.menuData = response.data.menu_json
        })
      }else {
        self.menuData = null
      }     
    },
    updateMenu(id) {
      console.log(id)
      console.log(this.menuData)
      if(id > 0) {
        this.axios.post(API_URL+'admin_menu',{id: id, data: JSON.stringify(this.menuData)})
        .then((response) => {
          let obj = response.data
          obj.status = true ? this.$store.dispatch('alertSuccess', obj.message) : this.$store.dispatch('alertError', obj.message);
        })
      }else {
        self.menuData = null
      } 
    },
  },
  mounted() {
    this.viewMenuCategory()
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
</style>
